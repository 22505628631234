import React from 'react';

import DesktopLayout from '@/components/modules/Layouts/DesktopLayout';
import MobileLayout from '@/components/modules/Layouts/MobileLayout';

const MainLayout: React.FC<{
  isHeader?: boolean;
  isFooter?: boolean;
  isMobile?: boolean;
  children?: React.ReactNode;
}> = ({ children, isHeader = true, isFooter = true, isMobile = false }) => {
  return (
    <>
      {isMobile ? (
        <MobileLayout isHeader={isHeader} isFooter={isFooter}>
          {children}
        </MobileLayout>
      ) : (
        <DesktopLayout>
          <div>{children}</div>
        </DesktopLayout>
      )}
    </>
  );
};

export default MainLayout;
