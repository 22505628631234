// 'use client';
import React from 'react';

import MobileHeader from '@/components/modules/Layouts/MobileLayout/Header/MobileHeader';
import FooterMobile from '@/components/modules/Menu/FooterMobile';

const MobileLayout: React.FC<any> = ({
  children,
  isHeader = true,
  isFooter = true,
}) => {
  return (
    <div
      style={{
        background: 'transparent',
      }}
      className='!flex min-h-screen flex-col'
    >
      {isHeader && <MobileHeader />}
      <main>{children}</main>
      {isFooter && <FooterMobile />}
    </div>
  );
};

export default MobileLayout;
