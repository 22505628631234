import clsx from 'clsx';
import React, { ComponentPropsWithoutRef } from 'react';

import DesktopFooter from '@/components/modules/Layouts/DesktopLayout/Footer';
import DesktopHeader from '@/components/modules/Layouts/DesktopLayout/Header';

const DesktopLayout: React.FC<ComponentPropsWithoutRef<'div'>> = ({
  children,
}) => {
  return (
    <div id='main' className={clsx('h-full')}>
      <DesktopHeader />
      <main className='!main-content relative w-full'>{children}</main>
      <DesktopFooter />
    </div>
  );
};

export default DesktopLayout;
