'use client';
import * as React from 'react';

import useClientTranslation from '@/components/hooks/useClientTranslation';
import MainLayout from '@/components/modules/Layouts/MainLayout';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  const { t, currentLink } = useClientTranslation('NotFoundPage');

  return (
    <MainLayout>
      <main className='bg-[#EDF3F1]'>
        <section className='main-content flex flex-col items-center justify-center bg-white'>
          <div className='layout flex min-h-[50vh] flex-col items-center justify-center text-center text-black'>
            <h1 className='mt-8 whitespace-pre-line text-2xl md:mb-10 md:text-4xl'>
              {t('someThingWrong')}
            </h1>
            <a
              href={currentLink('/') as string}
              className='text-secondary-1 mt-5 underline'
            >
              <button className='text-15 flex h-12 items-center whitespace-pre rounded-[32px] bg-[#D3A300] px-5 font-bold text-[#FCFCFC] hover:brightness-75'>
                {t('backHomeBtn')}
              </button>
            </a>
          </div>
        </section>
      </main>
    </MainLayout>
  );
}
